import React, { useState, useEffect } from 'react';
import { Analytics } from "@vercel/analytics/react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Card, CardContent, CircularProgress, Box, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCNQfdg4Cuig1gWe-bfp8rBCDTbigNaQg4",
  authDomain: "paymentzero-14dd4.firebaseapp.com",
  projectId: "paymentzero-14dd4",
  storageBucket: "paymentzero-14dd4.appspot.com",
  messagingSenderId: "224717553078",
  appId: "1:224717553078:web:060402b7ba216214781517",
  measurementId: "G-DR6XME908L"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Styled components (keeping existing styles and adding new ones)
const StyledAppBar = styled(AppBar)`
  background: #121212;
`;

const StyledCard = styled(motion(Card))`
  margin-bottom: 1rem;
  background: #1e1e1e;
  color: #ffffff;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const CenteredBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #121212;
`;

const AppContainer = styled(Container)`
  background: #181818;
  color: #ffffff;
  padding: 2rem;
  min-height: 100vh;
`;

const SignInButton = styled(Button)`
  background-color: #3f51b5;
  &:hover {
    background-color: #303f9f;
  }
`;

const LogoutButton = styled(Button)`
  background-color: #f44336;
  &:hover {
    background-color: #d32f2f;
  }
`;

const HeroSection = styled(Box)`
  text-align: center;
  padding: 4rem 0;
`;

const FeatureSection = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2rem;
`;

const CTAButton = styled(Button)`
  background-color: #3f51b5;
  color: white;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  margin-top: 2rem;
  &:hover {
    background-color: #303f9f;
  }
`;

// New styled component for the search and filter section
const SearchFilterContainer = styled(Box)`
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: white;
  }
  .MuiInputLabel-root {
    color: #333;
  }
`;

const FilterSelect = styled(Select)`
  background-color: white;
  .MuiSelect-icon {
    color: #333;
  }
`;

const SearchButton = styled(Button)`
  background-color: #3f51b5;
  color: white;
  &:hover {
    background-color: #303f9f;
  }
`;

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [internships, setInternships] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [page, setPage] = useState(1);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      if (user && user.emailVerified) {
        fetchInternships();
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      if (!result.user.emailVerified) {
        await sendEmailVerification(result.user);
        alert("Verification email sent. Please check your inbox.");
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setUser(null);
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  const fetchInternships = async (searchQuery = '', category = 'all', pageNum = 1) => {
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '7fe5fb283cmshf74d6692d8deee5p1feac1jsn482e91d07d51',
        'X-RapidAPI-Host': 'jsearch.p.rapidapi.com'
      }
    };

    let query = `web3 ${category !== 'all' ? category : ''} ${searchQuery}`.trim();
    
    try {
      const response = await fetch(`https://jsearch.p.rapidapi.com/search?query=${encodeURIComponent(query)}&page=${pageNum}&num_pages=1`, options);
      const data = await response.json();
      if (pageNum === 1) {
        setInternships(data.data);
      } else {
        setInternships(prevInternships => [...prevInternships, ...data.data]);
      }
    } catch (error) {
      console.error("Error fetching internships:", error);
    }
  };

  const handleSearch = () => {
    setPage(1);
    fetchInternships(searchTerm, filterCategory);
  };

  const handleFilterChange = (event) => {
    setFilterCategory(event.target.value);
    setPage(1);
    fetchInternships(searchTerm, event.target.value);
  };

  const loadMoreJobs = () => {
    setPage(prevPage => prevPage + 1);
    fetchInternships(searchTerm, filterCategory, page + 1);
  };

  if (loading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  return (
    <Router>
      <StyledAppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Web3 Internships
          </Typography>
          <Button color="inherit" component={Link} to="/">Home</Button>
          {user ? (
            <LogoutButton color="inherit" onClick={handleSignOut}>Logout</LogoutButton>
          ) : (
            <SignInButton color="inherit" onClick={signInWithGoogle}>Sign In</SignInButton>
          )}
        </Toolbar>
      </StyledAppBar>

      <AppContainer>
        <Routes>
          <Route path="/" element={
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {user && user.emailVerified ? (
                <div>
                  <Typography variant="h4" gutterBottom>
                  <Analytics/>
                    Welcome to Web3 Internship Opportunities
                  </Typography>
                  <SearchFilterContainer>
                    <SearchTextField
                      label="Search Jobs"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ marginRight: '1rem', flexGrow: 1 }}
                    />
                    <FormControl variant="outlined" style={{ minWidth: 120, marginRight: '1rem' }}>
                      <InputLabel htmlFor="category-select">Category</InputLabel>
                      <FilterSelect
                        value={filterCategory}
                        onChange={handleFilterChange}
                        label="Category"
                        inputProps={{
                          name: 'category',
                          id: 'category-select',
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="blockchain">Blockchain</MenuItem>
                        <MenuItem value="smart contracts">Smart Contracts</MenuItem>
                        <MenuItem value="defi">DeFi</MenuItem>
                        <MenuItem value="nft">NFT</MenuItem>
                      </FilterSelect>
                    </FormControl>
                    <SearchButton variant="contained" onClick={handleSearch}>
                      Search
                    </SearchButton>
                  </SearchFilterContainer>
                  {internships.map((internship, index) => (
                    <StyledCard
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <CardContent>
                        <Typography variant="h6">{internship.job_title}</Typography>
                        <Typography variant="body2">{internship.employer_name}</Typography>
                        <Typography variant="body2">{internship.job_description.slice(0, 150)}...</Typography>
                        <Button variant="contained" color="secondary" href={internship.job_apply_link} target="_blank">Apply</Button>
                      </CardContent>
                    </StyledCard>
                  ))}
                  <Button variant="contained" color="primary" onClick={loadMoreJobs} style={{ marginTop: '1rem' }}>
                    Load More Jobs
                  </Button>
                </div>
              ) : (
                // Landing page content (unchanged)
                <>
                  <HeroSection>
                    <Typography variant="h2" gutterBottom>
                      Launch Your Web3 Career
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Discover exciting internship opportunities in the world of blockchain and decentralized technologies
                    </Typography>
                    <CTAButton variant="contained" onClick={signInWithGoogle}>
                      Sign In to Explore Opportunities
                    </CTAButton>
                  </HeroSection>

                  <FeatureSection>
  <StyledCard>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        Gain Real-World Experience
      </Typography>
      <Typography variant="body1">
        Immerse yourself in cutting-edge Web3 projects and learn directly from industry experts, equipping you with the skills necessary to thrive in the decentralized economy.
      </Typography>
    </CardContent>
  </StyledCard>
  <StyledCard>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        Build Your Professional Network
      </Typography>
      <Typography variant="body1">
        Connect with peers and professionals in the Web3 space, opening doors to valuable mentorship and collaboration opportunities that can propel your career forward.
      </Typography>
    </CardContent>
  </StyledCard>
  <StyledCard>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        Launch Your Career in Web3
      </Typography>
      <Typography variant="body1">
        Equip yourself with sought-after skills and hands-on experience, positioning yourself as a competitive candidate in the fast-growing Web3 job market.
      </Typography>
    </CardContent>
  </StyledCard>
</FeatureSection>

                </>
              )}
            </motion.div>
          } />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;